import { Navigate } from "react-router-dom";

const Protected = ({ authorized, children }) => {
    if(!authorized) {
        return <Navigate to="/" replace />
    }

    return children
};

export default Protected;