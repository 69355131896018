import React, {useState, useEffect} from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {Home, Defoamer, Auth, Cleaner, Coatings, Tech, Label, Toll, Sds, About} from "./pages";
import { Magnum408, MagnumN12, MagnumS10, MagCleanA14, MagCleanCE30, Magnum464 } from "./components/pdfs";
import { Link } from "react-router-dom";
import Protected from "./components/Protected";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import './components/navbar/logo.css'

function App() {
  const authPass = ["defoamer1", "defoamer2", "defoamer3", "defoamer4", "defoamer5"]
  const [authorized, setAuthorized] = useState(false);
  const [pass, setPass] = useState({pass: ""})

  const checkAuth = (event) => {
    if (authPass.indexOf(event) > -1) {
        authorize();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPass({ ...pass, [name]: value});
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log(pass);
    
    checkAuth(pass.pass);
  }

  useEffect(() => {
    setAuthorized(JSON.parse(window.localStorage.getItem('auth')));
  }, []);

  useEffect(() => {
    window.localStorage.setItem('auth', authorized);
  }, [authorized]);

  const authorize = () => {
      return setAuthorized(true);
  }

  const deAuthorize = () => {
      return setAuthorized(false)
  }

  return (
      <Router>
        <div>

        <div class="modal fade" id="contactModal" tabindex="-1" aria-labelledby="contactModalLabel" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="contactModalLabel">Contact Us</h5>
                      </div>
                      <div class="modal-body">
                      info@caliberchemical.com
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
          <>
          <div>
            <nav className="navbar navbar-light container-fluid d-inline-flex">
                <Link className="m-2 d-inline-flex w-75" to="/">
                    <img className="logo" src={require("./images/CaliberLogo2.png")} alt="Caliber Chemical" />
                </Link>

                {authorized ? (
                    <div className="d-inline-flex">
                        <Link className="btn btn-light btn-outline-dark m-2" to="/sds">SDS</Link>
                        <button className="btn btn-light btn-outline-dark my-2 my-sm-0 btn-lg" onClick={deAuthorize}>Logout</button>
                    </div>
                    
                ) : (
                    <form onSubmit={handleFormSubmit} className="form-inline d-inline-flex my-2 my-lg-0">
                        <input className="form-control mr-sm-2" type="password" name="pass" placeholder="" aria-label="*" onChange={handleInputChange} />
                        <button className="btn btn-light btn-outline-dark my-2 my-sm-0" type="submit">Login</button>
                    </form>
                )}
            </nav>
        </div>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/auth" element={<Auth />} />
              <Route exact path="/defoam" element={<Defoamer />} />
              <Route exact path="/cleaner" element={<Cleaner />} />
              <Route exact path="/tech" element={<Tech />} />
              <Route exact path="/coatings" element={<Coatings />} />
              <Route exact path="/label" element={<Label />} />
              <Route exact path="/toll" element={<Toll />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/sds" element={
              <Protected authorized={authorized}><Sds /></Protected>
              } />
              <Route exact path="/Magnum408" element={
                <Protected authorized={authorized}><Magnum408 /></Protected>
              } />
              <Route exact path="/MagnumN12" element={
                <Protected authorized={authorized}><MagnumN12 /></Protected>
              } />
              <Route exact path="/MagnumS10" element={
                <Protected authorized={authorized}><MagnumS10 /></Protected>
              } />
              <Route exact path="/Magnum464" element={
                <Protected authorized={authorized}><Magnum464 /></Protected>
              } />
              <Route exact path="/MagCleanA14" element={
                <Protected authorized={authorized}><MagCleanA14 /></Protected>
              } />
              <Route exact path="/MagCleanCE30" element={
                <Protected authorized={authorized}><MagCleanCE30 /></Protected>
              } />
            </Routes>

            <div className="container-fluid footerMain fixed-bottom">
              <div className="row justify-content-between">
                <div className="col text-start">
                  <p>Caliber Chemical Technologies, LLC</p>
                </div>
                <div className="col text-center">
                  <p>Newnan, GA 30263</p>
                </div>
                <div className="col text-end">
                  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#contactModal">
                    Contact Us
                  </button>
                </div>

              </div>
            </div>
          </>
        </div>
      </Router>
      
  );
}

export default App;
