import React from "react";

export default function Defoamer() {
    return(
        <div>
            <div className="jumbotron jumbotron-fluid m-3 bgImg2">
                <div className="container">
                    <h2 className="display-3">Defoamers / Antifoam</h2>
                    <p className="card-text"><strong>Our specialty is custom formulation. In this era of ever-changing environmental regulations, process
                        additives must be adapted to meet new standards. That’s where Caliber excels. Our team has
                        experience in a wide variety of industries and have made it our business to understand the unique foam
                        control challenges of each process. This process expertise allows us to formulate defoamers and other
                        process additives for each facility’s unique requirements.
                        <br/>
                        We also offer a full line of traditional defoamers such as silicone emulsions, water-based, oil-based, and
                        various non-silicone, non-oil defoamers for select applications.
                        <br/>
                        Industries we serve:</strong>
                    </p>
                        <ul>
                            <li className="card-text"><strong>Paper and paperboard manufacturing</strong></li>
                            <li className="card-text"><strong>Textiles</strong></li>
                            <li className="card-text"><strong>Wastewater</strong></li>
                            <li className="card-text"><strong>Mining</strong></li>
                            <li className="card-text"><strong>Food processing (both direct and indirect contact applications)</strong></li>
                            <li className="card-text"><strong>Metalworking fluids</strong></li>
                            <li className="card-text"><strong>Paints and coatings</strong></li>
                            <li className="card-text"><strong>HI&I</strong></li>
                        </ul>
                </div>
            </div>
        </div>
    )
}