import React from "react";

export default function Label() {
    return(
        <div>
            <div className="jumbotron jumbotron-fluid m-3 bgImg5">
                <div className="container">
                    <h2 className="display-3">Private Label Program and Contract Manufacturing</h2>
                    <p className="lead"><strong>Our state-of-the-art formulations have continued life under other names. We have a robust private
                        label program, where we manufacture under contract select products from our formulary and ship them
                        either to your warehouse, or blind-ship directly to your customers under your name and label. We
                        provide blind bills of lading and SDS with your company name.
                        <br/>
                        Also, we offer formulary services where we help you optimize your formulation or jointly develop a
                        product idea.
                        <br/>
                        We maintain confidentiality throughout the entire process.</strong>
                        <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    </p>
                </div>
            </div>
        </div>
    )
}