import React from "react";
import { Link } from "react-router-dom";
import "../components/navbar/logo.css"
import "../index.css"

export default function Home() {
    return(
        <div>
            <div className="jumbotron jumbotron-fluid bgImg">
                <div className="container">
                    <h3 className="display-4 font-pls text-center">Caliber Chemical Technologies</h3>
                    <hr className="my-4"></hr>
                    <p className="lead"><strong>Caliber Chemical manufactures custom process chemicals for a variety of industries.  From metals to food, water treatment to pulp and paper.  Our specialty is surface and interface chemistry.  Custom antifoams and defoamers, foaming agents, cleaners, surface prep, release agents and the like.  Let us assist you with your process and specialty chemical needs.</strong></p>
                    <Link to="/about" className="btn btn-primary">About Us</Link>
                </div>
            </div>

            <div className="row row-cols-1 row-cols-md-2 m-4 d-flex">
                <div className="col mb-4">
                    <div className="card text-center w-100 h-100">
                        <img src={require("../images/foam-small.png")} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h4 className="card-title">Defoamers / Antifoams</h4>
                            <Link to="/defoam" className="btn btn-primary">Learn More</Link>

                        </div>
                    </div>
                </div>

                <div className="col mb-4">
                    <div className="card text-center w-100 h-100">
                    <img src={require("../images/cleaner-small.png")} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h4 className="card-title">Industrial Cleaners</h4>
                            <Link to="/cleaner" className="btn btn-primary">Learn More</Link>
                        </div>
                    </div>
                </div>

                <div className="col mb-4">
                    <div className="card text-center w-100 h-100">
                        <img src={require("../images/coating-small.png")} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h4 className="card-title">Coatings and Additives</h4>
                            <Link to="/coatings" className="btn btn-primary">Learn More</Link>
                        </div>
                    </div>
                </div>

                <div className="col mb-4">
                    <div className="card text-center w-100 h-100">
                        <img src={require("../images/engineering-small.png")} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h4 className="card-title">Engineering</h4>
                            <Link to="/tech" className="btn btn-primary">Learn More</Link>
                        </div>
                    </div>
                </div>

                <div className="col mb-4">
                    <div className="card text-center w-100 h-100">
                        <img src={require("../images/tanks-small.png")} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h4 className="card-title">Toll Manufacturing</h4>
                            <Link to="/toll" className="btn btn-primary">Learn More</Link>
                        </div>
                    </div>
                </div>

                <div className="col mb-4">
                    <div className="card text-center w-100 h-100">
                        <img src={require("../images/drums-small.png")} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h4 className="card-title">Private Label Program- Contract Manufacturing</h4>                   
                            <Link to="/label" className="btn btn-primary">Learn More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
