import React from "react";

export default function About() {
    return(
        <div>
            <div className="jumbotron jumbotron-fluid m-3">
                <div className="container">
                    <h2 className="display-3">About Us</h2>
                    <p className="card-text">Caliber Chemical was started in 2006 to provide custom-formulated products and engineering solutions to process chemistry challenges in a wide spectrum of industries.  Whether it be environmental/regulatory compliance, cost optimization or production gains, let us assist you with your next project.
                    </p>
                </div>
            </div>
        </div>
    )
}