import React from "react";

export default function Cleaner() {
    return(
        <div>
            <div className="jumbotron jumbotron-fluid m-3 bgImg3">
                <div className="container">
                    <h2 className="display-3">Industrial Cleaners</h2>
                    <p className="card-text"><strong>Caliber offers a line of neutral pH cleaners and degreasers that are more environmentally suitable for
                        closed loop systems. We can work with your engineers to develop the best cleaning/degreasing system
                        using your existing equipment.</strong>
                    </p>
                    <ul>
                        <li><strong>MagClean N-85 series- designed for cleaning and prepping chrome-moly steel for finishing.</strong></li>
                        <li><strong>MagClean N-81 series- formulated for degreasing aluminum alloys prior to welding and anodizing.</strong></li>
                    </ul>
                    <p className="card-text">
                    <strong>We also manufacture more traditional caustic cleaners as well as acidic cleaners and descalants.</strong>
                    </p>
                    <ul>
                        <li><strong>MagClean A-14 Low odor muriatic acid</strong></li>
                        <li><strong>MagClean NC-66 Neutral pH Degreaser Concentrate</strong></li>
                    </ul>
                    <p><br/><br/><br/><br/><br/><br/><br/></p>
                </div>
            </div>
        </div>
    )
}