import React, { useState } from "react";
//import {checkAuth} from "../utils/auth.js"

export default function Auth() {
    const [authData, setAuthData] = useState({password: ""});

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAuthData({ ...authData, [name]: value });
      };

    function checkAuth(event) {
        event.preventDefault();
        //console.log(authData);
    }

    return( 
        <div>
            <form onSubmit={checkAuth}>
                <div className="form-group mx-sm-2 mb-2">
                    <input type="password" name="password" onChange={handleInputChange} className="form-control" id="inputPassword" placeholder="Password"></input>
                </div>
                <button type="submit" className="btn btn-primary mb-2">Confirm</button>
            </form>
        </div>
    )
}