import React from "react";

export default function Tech() {
    return(
        <div>
            <div className="jumbotron jumbotron-fluid m-3 bgImg7">
                <div className="container">
                    <h2 className="display-3">Process Engineering</h2>
                    <p className="lead"><strong>Caliber offers engineering services, from scale-up to manufacturing process design. We have extensive
                        experience in scaling up lab reactions to bulk production. We also specialize in process modifications to
                        comply with new environmental and safety requirements, while preserving product yield and quality.</strong>
                        <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    </p>
                </div>
            </div>
        </div>
    )
}