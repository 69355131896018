import React from "react";

export default function Toll() {
    return(
        <div>
            <div className="jumbotron jumbotron-fluid m-3 bgImg6">
                <div className="container">
                    <h2 className="display-3">Toll Manufacturing</h2>
                    <p className="lead"><strong>At Caliber, we have the capability to toll manufacture a variety of process chemicals and intermediates,
                        from small quantities to truckloads. You provide the formulation and we will make to your
                        specifications. If you would like to discuss reselling one of our formulations, or need assistance in
                        developing a formulation, then we do that as well. Please refer to our Private Label Program for more
                        details.</strong>
                        <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    </p>
                </div>
            </div>
        </div>
    )
}