import React from "react";

export default function Coatings() {
    return(
        <div>
            <div className="jumbotron jumbotron-fluid m-3 bgImg4">
                <div className="container">
                    <h2 className="display-3">Coatings and Additives</h2>
                    <p className="lead"><strong>Caliber has developed specialty coatings and binders for select customers. Let us help you with your
                        next specialty coating application.
                        <br/>
                        Caliber offers a line of coatings additives for performance enhancement. We are happy to discuss your
                        coatings performance criteria and help you develop a solution.</strong>
                        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    </p>
                </div>
            </div>
        </div>
    )
}