import React from "react";
import { Link } from "react-router-dom";

export default function Sds(){
    return(
        <div>
            <div className="list-group list-group-flush">
                <Link className="list-group-item" to="/Magnum408">Magnum 408</Link>
                <Link className="list-group-item" to="/MagnumS10">Magnum S-10</Link>
                <Link className="list-group-item" to="/MagnumN12">Magnum N-12 Snow</Link>
                <Link className="list-group-item" to="/Magnum464">Magnum 464</Link>
                <Link className="list-group-item" to="/MagCleanA14">MagClean A14 Safe Acid</Link>
                <Link className="list-group-item" to="/MagCleanCE30">MagClean CE-30 Degreaser</Link>
            </div>
        </div>
    )
}